import { useCallback, useEffect } from 'react';
import { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Button } from 'design-system/atoms/button';
import { trackOverviewPage } from 'tracking/Mixpanel';
import { oTelCountPage } from 'tracking/OpenTelemetry';
import { useSettingsContext } from 'context';
import { getCanonicalUrl, getHostnameUrl } from 'common/utils';
import {
  ButtonsContainer,
  OverviewContentWrapper,
  OverviewWrapper,
  Title,
  VideoContainer,
} from 'styles/overview.styled';

const Overview: NextPage = () => {
  const { t } = useTranslation('overview');
  const { darkMode } = useSettingsContext();

  const router = useRouter();

  const injectNewsreaderFontFace = useCallback(() => {
    if (!document.getElementById('newsreaderBoldItalic-font-face')) {
      const newsreaderFontFaceStyle = document.createElement('style');
      newsreaderFontFaceStyle.id = 'newsreaderBoldItalic-font-face';
      newsreaderFontFaceStyle.appendChild(
        document.createTextNode(`
        @font-face {
          font-family: 'Newsreader';
          src: url('/typefaces/Newsreader/NewsreaderBoldItalic.woff2') format('woff2');
          font-weight: 700;
          font-style: italic;
          font-display: swap;
        }`)
      );
      document.head.append(newsreaderFontFaceStyle);
    }
  }, []);

  const removeNewsreaderFontFace = useCallback(() => {
    if (document.getElementById('newsreaderBoldItalic-font-face')) {
      document.getElementById('newsreaderBoldItalic-font-face')?.remove();
    }
  }, []);

  useEffect(() => {
    removeNewsreaderFontFace();
    injectNewsreaderFontFace();

    return () => {
      removeNewsreaderFontFace();
    };
  }, [injectNewsreaderFontFace, removeNewsreaderFontFace]);

  // Track Mixpanel page view
  useEffect(() => {
    trackOverviewPage();
  }, []);

  // Count OpenTelemetry page view
  useEffect(() => {
    oTelCountPage(router.pathname);
  }, [router.pathname]);

  return (
    <>
      <Head>
        <title>{t('head.title')}</title>
        <meta
          name="description"
          content={t('head.description')}
        />
        <meta
          name="keywords"
          content={t('head.keywords')}
        />
        <meta
          property="og:title"
          content={t('head.title')}
        />
        <meta
          property="og:description"
          content={t('head.description')}
        />
        <meta
          property="og:image"
          content={`${getHostnameUrl()}${t('head.image')}`}
        />
        <meta
          property="og:image:alt"
          content={t('head.image-alt')}
        />
        <meta
          property="og:url"
          content={getCanonicalUrl(router.asPath)}
        />
        <meta
          property="og:type"
          content="article"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:site"
          content={t('head.twitter-site')}
        />
        <meta
          name="twitter:title"
          content={t('head.title')}
        />
        <meta
          name="twitter:description"
          content={t('head.description')}
        />
        <meta
          name="twitter:image"
          content={`${getHostnameUrl()}${t('head.image')}`}
        />
        <meta
          name="twitter:image:alt"
          content={t('head.image-alt')}
        />
        <link
          rel="canonical"
          href={getCanonicalUrl(router.asPath)}
          key="canonical"
        />
      </Head>

      <OverviewWrapper>
        <OverviewContentWrapper>
          <Title dangerouslySetInnerHTML={{ __html: t('title') }} />
          <VideoContainer darkMode={darkMode}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/_HokdfhrJZE?si=4YRgFpEzRV3lLJ06&color=white&modestbranding=1&rel=0"
              title={t('iframe-title')}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </VideoContainer>
          <ButtonsContainer>
            <Button
              render="contrast"
              iconLabel="email"
              darkMode={darkMode}
              href={t('button-contact-us.link')}
            >
              {t('button-contact-us.label')}
            </Button>
            <Button
              render="contrast"
              iconLabel="instagram"
              darkMode={darkMode}
              href={t('button-instagram.link')}
              target="_blank"
            >
              {t('button-instagram.label')}
            </Button>
          </ButtonsContainer>
        </OverviewContentWrapper>
      </OverviewWrapper>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? 'en', ['common', 'nav', 'overview'])),
    },
  };
};

export default Overview;
