import styled from '@emotion/styled';
import { mq } from './mediaqueries.styled';
import { GREY_2, GREY_6, NINJA_GREEN } from 'design-system/global/colors';

interface Props {
  darkMode?: boolean;
}

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  /* MOBILE */
  min-height: 640px;
  margin: -120px 0 0;
  /* TABLET */
  ${mq['tablet']} {
    min-height: 900px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin: -184px 0 0;
    min-height: 860px;
  }
`;

export const OverviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* MOBILE */
  margin-top: 68px;
  /* TABLET */
  ${mq['tablet']} {
    margin-top: 0;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: 78px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: 700;
  /* MOBILE */
  margin-bottom: 32px;
  font-size: 32px;
  line-height: 33px;
  letter-spacing: -0.65px;
  .newsreader {
    font-family: 'Newsreader', serif;
    color: ${NINJA_GREEN};
    font-size: 33px;
    font-style: italic;
    line-height: 33px;
    letter-spacing: -0.67px;
  }
  /* TABLET */
  ${mq['tablet']} {
    font-size: 42px;
    line-height: 43px;
    letter-spacing: -0.85px;
    .newsreader {
      font-size: 43px;
      line-height: 43px;
      letter-spacing: -0.87px;
    }
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  background-color: ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  overflow: hidden;
  margin-bottom: 40px;
  /* MOBILE */
  width: 100%;
  max-width: 440px;
  aspect-ratio: 16 / 9;
  border-radius: 16px;
  border: 2px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `4px 4px 0px ${GREY_6}`)};
  /* TABLET */
  ${mq['tablet']} {
    max-width: 798px;
    border-radius: 32px;
    border: 3px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
    box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `8px 8px 0px ${GREY_6}`)};
  }

  iframe {
    border: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  /* MOBILE */
  flex-direction: column;
  a {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  /* TABLET */
  ${mq['tablet']} {
    flex-direction: row;
    a {
      margin-bottom: 0;
      margin-right: 16px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
